
<i18n locale="th" lang="yaml" >
page.title : "{name} :: ข้อมูล Lot"
tab.base : "สรุป"
tab.parts : "อะไหล่"
tab.usages : "Drone"
action.title : "Drone Lot Action"

action.duplicate : "Duplicate Drone Lot"
action.duplicate.tooltip : "ทำการสร้าง Drone Lot ใหม่ โดยใช้ Lot นี้เป็นต้นแบบ (อะไหล่)"

action.delete : "ลบข้อมูล Drone Lot นี้ออกจากระบบ"
action.delete.tooltip : "ทำการลบ Drone Lot นี้ออกจากระบบ โดยจะลบได้เฉพาะ Drone Lot ที่ยังไม่ได้เพิ่ม Drone เข้ามาเท่านั้น"
action.delete.title : "ลบข้อมูล"
action.delete.content : "ต้องการลบข้อมูล Drone Lot {name} ? คำเตือน ไม่สามารถนำข้อมูลกลับมาได้"
action.delete.ok : "@:common.confirm"
action.delete.success : "Drone Lot {name} ถูกลบข้อมูลออกจากระบบแล้ว"
action.delete.cannot : "ไม่สามารถลบข้อมูลได้ มีข้อมูล Drone ในระบบ"
</i18n>

<template>
	<div class="page page-padding page-fit-mobile page-fit-tablet">
		<div class="mylayout-left-right responsive">
			<div class="mylayout-left">
				<DroneLotCard
					:loading="pageLoading"
					:model="model"
					:lot="lot"
					:show-update="!$route.meta.hideTab" />

				<a-card
					v-if="canMakeAction"
					:loading="pageLoading"
					:bordered="false"
					:title="$t('action.title')"
					style="margin-top:24px">
					<div class="mybutton-group-vertical">
						<a-button :title="$t('action.duplicate.tooltip')"
							@click="goDuplicate">
							<a-icon type="copy" />
							{{$t('action.duplicate')}}
						</a-button>
						<a-button :disabled="!canDelete"  :title="$t('action.delete.tooltip')"
							type="danger" @click="askToDelete">
							<a-icon type="delete" />
							{{$t('action.delete')}}
						</a-button>
					</div>

				</a-card>
			</div>

			<div class="mylayout-right">
				<template v-if="!$route.meta.hideTab">
					<a-menu
						mode="horizontal"
						type="inner"
						class="mytab-menu"
						:selected-keys="selectedMenu">
						<a-menu-item key="drone_lot/base">
							<my-router-link name='drone_lot/base' :param-value="$route.params.id">
								<a-icon type="pie-chart" /> {{$t('tab.base')}}
							</my-router-link>
						</a-menu-item>
						<a-menu-item v-if="canViewAllDrones" key="drone_lot/usages">
							<my-router-link name='drone_lot/usages' :param-value="$route.params.id">
								<DroneIcon class="anticon" /> {{$t('tab.usages')}}
							</my-router-link>
						</a-menu-item>
						<a-menu-item key="drone_lot/parts">
							<my-router-link name='drone_lot/parts' :param-value="$route.params.id">
								<a-icon type="deployment-unit" /> {{$t('tab.parts')}}
							</my-router-link>
						</a-menu-item>

					</a-menu>
					<div class="mytab-content">
						<keep-alive>
							<router-view :model="model" :lot="lot"></router-view>
						</keep-alive>
					</div>
				</template>
				<template v-else>
					<router-view :model="model" :lot="lot"></router-view>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import DroneLotCard from "@components/drone/DroneLotCard.vue"
import DroneIcon from "@components/icon/DroneIcon.vue"
import axios from "axios"
import {Menu} from "ant-design-vue"
export default {
	components : {
		DroneLotCard , DroneIcon,
		"a-menu" : Menu, "a-menu-item" : Menu.Item ,
	} ,
	mixins : [PageMixin,LoginInfoMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name:this.lot.lot_name})
		}
	} ,
	data() {
		return {
			model : {} ,
			lot : {} ,
			selectedMenu : []
		}
	} ,
	computed : {
		canMakeAction() {
			return !this.$route.meta.hideAction &&
				this.lot.id && this.$authorize('create','droneLot');
		} ,
		canDelete() {
			return this.canMakeAction && this.lot.num_drones <= 0
		} ,
		canViewAllDrones() {
			return this.$authorize('listAll','drone')
		}
	} ,
	watch : {
		$route(newVal) {
			if (!this.model || newVal.params.id != this.model.id) {
				this.fetchData()
			}
			this.updateSelectedMenu()
		}
	} ,
	mounted() {
		this.updateSelectedMenu()
		this.fetchData()
	} ,
	methods : {
		goDuplicate() {
			this.$open({name:'drone_lot/duplicate',params : {id : this.lot.id}})
		} ,
		updateSelectedMenu() {
			this.selectedMenu = [this.$route.name]
		} ,
		fetchData() {
			const lotId = this.$route.params.id;
			if (!lotId)
				return
			this.showPageLoading()
			this.clearBreadcrumbParams(['lotName','modelName','modelId'])
			axios.get("/api/drone-lots/"+lotId).then((response) => {
				this.model = response.data.data.drone_model;
				this.lot = response.data.data.drone_lot;
				this.addBreadcrumbParams({
					'modelName' : this.model.name ,
					'modelId' : this.model.id,
					'lotName' : this.lot.lot_name
				})
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=> {
				this.hidePageLoading()
			})
		} ,
		askToDelete() {
			if (this.lot.num_drones > 0) {
				this.$message.info(this.$t('action.delete.cannot'))
				return;
			}
			this.$confirm({
				title : this.$t('action.delete.title') ,
				content : this.$t('action.delete.content',{"name" : this.lot.lot_name}) ,
				okText : this.$t('action.delete.ok') ,
				maskClosable : true,
				onOk: ()=> {
					axios.delete("/api/drone-lots/"+this.lot.id).then((response) => {
						this.$router.push({name:'drone_model/view',params: {id : this.model.id}})
						this.$message.success(this.$t('action.delete.success',{"name" : this.lot.lot_name}))
					})
				}
			})
		}
	}
}
</script>
<style lang="less">
	.lot-action-title {
		text-decoration: underline;
		font-weight: 600;
		margin-bottom : 8px;
	}
</style>
